$colors: (
    primary: #146eb4,
    divider: #c8c8c8,
    accent: #ff9900,
    accent-transparent: transparentize(#ff9900, 0.8),
    accent-transparent-dark: transparentize(darken(#ff9900, 0.03), 0.8),
    cancel: #a3a8ae,
    background-dark: #f7f7f8,
);

$drawer-padding: 3em;
$dedup-darkened-rows: 0, 1, 7, 8, 9, 11, 12, 13;
$rows-with-divider: 0, 2, 3, 4, 5, 7, 8, 11, 12;
$dedup-darkened-rows-dell: 0, 6, 7, 8, 10, 11, 12;
$rows-with-divider-dell: 1, 2, 3, 4, 6, 7, 10, 11;
$table-text-padding-left: 12px;
@function colors($color-key) {
    @return map-get($colors, $color-key);
}

@function contains($list, $value) {
    $contains: false;
    @each $element in $list {
        @if $element == $value {
            $contains: true;
        }
    }
    @return $contains;
}

.drawer {
    width: 550px;
    padding: 12px $drawer-padding 0 $drawer-padding;
    color: #232f3e;
    position: relative;

    .drawer-close-icon {
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 12px;
        min-width: 33px;
        min-height: 33px;
    }

    .drawer-content-header {
        display: flex;
        direction: row;

        p {
            font-size: 15;
        }

        #drawer-header {
            color: colors(primary);
            padding: 10px;
            border-bottom: 2px solid colors(primary);
        }

        #drawer-header-fill {
            flex: 1;
            padding: 10px;
            border-bottom: 1px solid colors(divider);
        }
    }

    .drawer-buttons-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        flex-direction: row;

        .drawer-button {
            border: none;
            padding: 0;
            margin-bottom: 6px;
            background-color: inherit;
            margin-top: 6px;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
        }

        .drawer-button-edit {
            color: #ff9900;
        }

        .drawer-button-cancel {
            color: #a3a8ae;
            margin-right: 1em;
        }

        .drawer-button-save {
            color: #ff9900;
        }
    }

    table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        box-shadow: 0px 4px 50px rgba(239, 243, 244, 0.5);
        border-radius: 3px;
        margin-bottom: 2rem;
        thead {
            background: #e9ebec;
            tr {
                width: 100%;
                display: grid;
                grid-template-columns: 24rem minmax(8rem, 1fr) minmax(8rem, 1fr);
            }
            th {
                padding-top: 6px;
                padding-bottom: 6px;
                font-weight: normal;
            }
            th:first-child {
                text-align: left;
                padding-left: $table-text-padding-left;
            }
        }

        tbody {
            tr {
                width: 100%;
                display: grid;
                grid-template-columns: 20rem minmax(8rem, 1.2fr) minmax(8rem, 1fr);
                grid-gap: 0.2rem;

                td {
                    padding-top: 0.6rem;
                    padding-bottom: 1rem;
                    &:first-child{
                        padding-right: 0;
                        padding-left: $table-text-padding-left;
                    }
                }
                td:last-child {
                    text-align: center;
                }
            }
        }

        .table-cell-editable {
            text-align: center;
            padding: 0.6rem 0;
            input {
                max-width: 8rem;
                padding: 1rem 0;
                text-align: center;
                background: #ffffff;
                border: 1px solid #c8c8c8;
                box-sizing: border-box;
                border-radius: 2px;
                font-size: 1.5rem;
                margin: 0 auto;
            }
        }
    }

    .dedup-table {
        tbody {
            @for $i from 0 to 14 {
                tr:nth-child(#{$i + 1}) {
                    @if contains($dedup-darkened-rows, $i) {
                        background: colors(background-dark);

                        & > td:last-child {
                            background-color: colors(accent-transparent-dark);
                        }
                    } @else {
                        & > td:last-child {
                            background-color: colors(accent-transparent);
                        }
                    }

                    @if contains($rows-with-divider, $i) {
                        & > td:nth-last-child(-n + 2) {
                            border-bottom: 1px solid rgba(200, 200, 200, 0.38);
                        }
                    }
                }
            }
        }
    }
    .dedup-table-dell {
        tbody {
            @for $i from 0 to 13 {
                tr:nth-child(#{$i + 1}) {
                    @if contains($dedup-darkened-rows-dell, $i) {
                        background: colors(background-dark);

                        & > td:last-child {
                            background-color: colors(accent-transparent-dark);
                        }
                    } @else {
                        & > td:last-child {
                            background-color: colors(accent-transparent);
                        }
                    }

                    @if contains($rows-with-divider-dell, $i) {
                        & > td:nth-last-child(-n + 2) {
                            border-bottom: 1px solid rgba(200, 200, 200, 0.38);
                        }
                    }
                }
            }
        }
    }
    .table {
        tbody {
            tr:nth-child(even) {
                background: colors(background-dark);
            }

            @for $i from 0 to 9 {
                tr:nth-child(#{$i + 1}) {
                    @if contains($dedup-darkened-rows, $i) {
                        & td.table-cell-editable {
                            background-color: colors(accent-transparent-dark);
                        }
                    } @else {
                        & td.table-cell-editable {
                            background-color: colors(accent-transparent);
                        }
                    }
                }
            }
        }
    }
}

.close-dialog > .MuiPaper-root {
    position: absolute;
    top: 0;
    right: 550px;
    width: 450px;
    text-align: center;
    font-family: Lato;
    font-style: normal;
    border-radius: 2px;
    padding: 30px 0px;

    .MuiDialogTitle-root {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        padding-top: 0;
        font-family: Lato;

        .MuiTypography-h6 {
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
            letter-spacing: inherit;
        }
    }

    .MuiDialogContent-root {
        font-size: 15px;
        line-height: 24px;

        .MuiTypography-body1 {
            font-size: inherit;
        }

        .MuiTypography-colorTextSecondary {
            color: #232f3e;
        }
    }

    .MuiDialogActions-root {
        justify-content: space-evenly;
        width: 100%;

        .close-dialog-cancel {
            width: 180px;
            background-color: colors(cancel);
            border: none;
            color: white;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.1em;
        }

        .close-dialog-close {
            width: 180px;
            background-color: colors(accent);
            border: none;
            margin-left: 0;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.1em;
        }
    }
}
