@import '../../../../layout/theme.scss';

.input-group {
    display: flex;
    flex-direction: row;
    background-color: rgba($grey-dark, 0.5);
    border-radius: 3px 3px 0px 0px;
    border-right: 1px solid #ffffff;
    justify-content: center;
    align-items: center;

    &__title {
        color: #ffffff;
        font-weight: bold;
        font-size: 1.4rem;
        text-align: center;
        padding: 1.5rem 0.2rem;
    }
}

.flex {
    display: flex;

    &__align-center {
        align-items: center;
    }
}