@import '../../../../layout/theme.scss';

.source {
    &__couter {
        background-color: #ffffff;
        padding: 1.5rem 0;
        border-radius: 3px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(35, 47, 62, 0.3);

        h3 {
            font-weight: normal;
            font-size: $font-sm;
            margin-left: 1rem;
        }

        &:hover .cross {
            opacity: 1;
        }
    }

    &__icon {
        margin-left: 0.8rem;
    }
}

.button-remove {
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 300;
    position: relative;
    left: 1rem;

    &:focus {
        outline: none;
    }

    .cross {
        display: inline-block;
        opacity: 0;
        width: 1.7rem;
        height: 1.7rem;
        cursor: pointer;
        transition: opacity 0.1s ease-in-out;

        &::after,
        &::before {
            content: '';
            display: block;
            height: 1px;
            width: 100%;
            position: absolute;
            background-color: #a3a8ae;
            top: 50%;
        }

        &::after {
            transform: rotate(45deg);
        }

        &::before {
            transform: rotate(-45deg);
        }
    }
}

.grid {
    display: grid;
    grid-gap: 2px;
    margin-right: 2px;

    &.col1 {
        grid-template-columns: repeat(1, 1fr);
    }

    &.col2 {
        grid-template-columns: repeat(2, 1fr);
    }

    &.col3 {
        grid-template-columns: 2.3fr 1fr 1fr;
    }

    &.col4 {
        grid-template-columns: 0.9fr 1.0fr 1.1fr 0.9fr;
    }

    &.col5 {
        grid-template-columns: repeat(5, 1fr);
    }

    &.col6 {
        grid-template-columns: repeat(6, 1fr);
    }

    &.data-change-col {
        grid-template-columns: 1fr 0.9fr 0.9fr 1.2fr;
    }
}

.dedupe {
    display: flex;
    border: 1px solid rgba(35, 47, 62, 0.3);
    border-radius: 2px;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px;

    .edit-button {
        width: 15px;
        height: 15px;
        border: none;
        padding: 0px;
        background-color: transparent;
    }

    .truncate {
        width: 35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}