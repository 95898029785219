@import '../../layout/theme.scss';

#dialog-title {
    margin-bottom: 12px;
}

.MuiDialog-root {
    background-color: rgba(0, 0, 0, 0.7);
}

.export-dialog {
    max-width: 650px;
    min-width: 580px;
    min-height: 360px;
    background-color: white;
    display: flex;
    flex-direction: column;
    font-family: Lato;

    .export-dialog-content {
        flex-grow: 1;
        padding-right: 4rem;
        padding-bottom: 4rem;
        overflow-y: auto;

        &>span {
            color: grey;
        }

        .MuiFormControl-root {
            display: flex;
            margin-top: 20px;
            align-items: center;
            flex-direction: row;

            .form-label-container {
                min-width: 25rem;
                margin-right: 30px;
                color: rgba(0, 0, 0, 0.7);

            }

            label {
                font-size: 15px;
                font-family: Lato;
            }

            .flexDirectionRow {
                flex-direction: row;
            }
        }

        .details-item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .MuiSelect-outlined {
                padding: 8px;
            }

            input {
                text-align: start;
                padding-left: 10px;
            }

            .Mui-checked {
                color: #ff9900;
            }

            span {
                font-size: 14px;
                font-family: Lato;
            }

            .checkbox {
                svg {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }

    .export-dialog-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 50px;
        padding-top: 5px;

        a {
            text-decoration: none;
        }
    }
}